<template>
  <section class="price-square-meter">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>Quel est le prix au m² d’une maison neuve?</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/price-square-meter-1.jpg"
            alt="Quel est le prix au m² d’une maison neuve ?"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content">
        <div class="smartpage-card">
          <div class="card-content">
            <h2>Prix moyen au m2 d’une maison individuelle en 2022</h2>
            <p>
              Le prix moyen d’une maison neuve du m² en France est de 1 800 €* et en moyenne de 1 375€ chez Maisons Alysia.
            </p>
            <p>Voilà, vous avez la réponse à votre question…</p>
            <p>Mais attendez un peu.. en êtes-vous vraiment sûr ?</p>
            <p>
              Avoir en tête un prix de référence lorsque l’on fait construire, c’est bien mais
              comprendre tous les critères qui compose le devis d’un projet de construction ne
              serait-il pas plus utile ?
            </p>
            <p>
              Si vous êtes en pleine réflexion autour de votre projet de construction et que vous
              souhaitez comparer les offres des constructeurs avec un véritable œil de lynx, alors,
              suivez le guide…
            </p>
            <p>
              Alysia vous révèle tous les secrets pour s’engager sereinement avec votre constructeur
              !
            </p>
          </div>
          <div class="square-meter-price">
            <div class="square-meter-price-average">
              <p>PRIX MOYEN FRANCE</p>
              <p>1 800€ / m2*</p>
            </div>
            <div class="square-meter-price-alysia">
              <p>PRIX MOYEN MAISONS ALYSIA</p>
              <p>1 375€ / m2*</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-divider">
          <div class="smartpage-divider-content">
            <div class="smartpage-divider-img">
              <img src="@/assets/img/home.svg" alt="home icon" />
            </div>
            <div class="smartpage-divider-body">
              <h2>Les 6 Critères Essentiels pour comprendre le prix d’une maison</h2>
              <p>
                La construction d’une maison est <strong>un process complexe où intervient de nombreux
                critères,</strong> il en résulte une multitudes de réponses, un éventail très large de prix
                au m², ce qui ne va pas contenter le porteur de projet en quête d’informations…
              </p>
              <p>
                Pour y voir clair, il vous faut identifier ces fameux critères que l’ont peut
                résumer ainsi :
              </p>
            </div>
          </div>
        </div>
        <div class="smartpage-divider-rank">
          <div>
            <ul>
              <li>
                <p><span>1 -</span> Le type de constructeur</p>
              </li>
              <li>
                <p><span>2 -</span> L'emplacement et la nature du terrain</p>
              </li>
              <li>
                <p><span>3 -</span> Le type de maison</p>
              </li>
              <li>
                <p><span>4 -</span> Les choix du mode constructif</p>
              </li>
              <li>
                <p><span>5 -</span> Le niveau des prestations, des finitions et des équipements</p>
              </li>
              <li>
                <p><span>6 -</span> La situation géographique</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="fit-content">
        <div class="smartpage-card-criterion criterion-right">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>1</span>
              </div>
              <h2>Le type de Constructeur</h2>
            </div>
            <p>
              Ici, nous résonnerons <strong>Constructeur de maisons individuelles</strong>, pour une
              maison clé en main et un accompagnement de A à Z.
            </p>
            <p>
              Ce type de prestataires implique l’application stricte du CCMI (Contrat de Construction
              de Maison Individuelle), disposition légale qui offre toutes les garanties et les
              assurances exigibles par le législateur pour protéger l’acquéreur : en gros c’est ce qui
              va faire que vous allez faire construire sereinement.
              <strong
                >Ceci exclut donc les contrats d’architectes, de maitrise d’œuvre, de promotion ou de
                marchés de travaux, souvent plus coûteux.</strong
              >
            </p>
            <div class="smartpage-card-criterion-img">
              <img src="@/assets/img/pages/price-square-meter-2.jpg" alt="Maison Alysia moderne" />
              <img src="@/assets/img/pages/price-square-meter-3.jpg" alt="Maison Alysia contemporaine" />
            </div>
            <p>
              Regardez bien également le <strong>nombre de réalisations</strong> que l’entreprise
              effectue à l’année.
            </p>
            <p>
              Il faut savoir que sa capacité d’achat autorisée par le volume généré lui permet de
              réduire ses coûts de revient. Et la relation qu’il entretien avec son réseau d’artisans
              lui permet d’optimiser ses chantiers, de maitriser ses délais, et donc de réduire son
              coût global.
            </p>
            <img class="line-right" src="@/assets/img/pages/smartpage/line-1.svg" alt="Ligne de séparation entre les critères" />
          </div>
        </div>
        <div class="smartpage-card-criterion criterion-left">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>2</span>
              </div>
              <h2>L’emplacement et la nature du Terrain</h2>
            </div>
            <p>
              Le foncier est le nerf de la guerre : <strong>pas de terrain, pas de maison.</strong>
            </p>
            <p>
              Il conditionne le prix du projet de manière importante selon 2 éléments : son
              emplacement et la nature du terrain. <strong>L’emplacement</strong> est déterminant et
              c’est de la folie : <strong>les prix peuvent passer de 1 à 10</strong>
              sur quelques dizaines de kilomètres à surface égale.
            </p>
            <p>
              Proximité des grandes villes, sécurité et environnement agréable, commodités liées aux
              écoles, aux commerces, au transport… tous ces éléments devront peser dans la balance.
              Par exemple, imaginez : en choisissant une commune voisine un peu moins chère, vous
              pouvez avoir une chambre de plus !
            </p>
            <p>
              La nature du terrain : visez la simplicité :
              <strong>si la parcelle est plate, si le sous-sol est sain, tout va bien. Plus il est en pente, plus le coût s’élève,</strong>
              plus les modalités de construction seront techniquement coûteuses.
            </p>
            <p>
              <strong>Un terrain en lotissement sera également plus économique pour la construction</strong>
              qu’un terrain en diffus en termes de viabilisation, de bornages et d’aménagement des
              accès, tout en présentant plus de contraintes réglementaires (cependant un terrain
              souvent plus cher à l’achat, mais tenons-nous en au prix de la maison… ).
            </p>
            <img class="line-left" src="@/assets/img/pages/smartpage/line-2.svg" alt="Ligne de séparation entre les critères" />
          </div>
        </div>
        <div class="smartpage-card-criterion criterion-right criterion-home-type">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>3</span>
              </div>
              <h2>Le type de maison</h2>
            </div>
            <p>Premier élément d’importance : la <strong>surface souhaitée.</strong></p>
            <p>
              Plus la surface de la maison est grande, plus le prix de la maison l’est également :
              logique mais en revanche plus le prix au m² est censé baisser.
            </p>
            <p>
              Et là, peu de constructeur sont vraiment clairs et transparents sur ce point. Par
              exemple,
              <strong>Maisons Alysia est le seul à proposer un prix au m2 supplémentaire, et à 850 € seulement !</strong>
            </p>
            <p>
              Pour la forme, plus <strong>le plan de la maison est simple</strong> (carré ou
              rectangulaire, de plain pied), <strong>plus le prix au m² sera modéré.</strong>
            </p>
            <p>
              A contrario, plus le plan sera sophistiqué et différenciant (en L, en U, complexe), avec
              étage ou même avec un ou des demi-niveaux, plus la maison aura une volumétrie généreuse,
              plus l’addition sera salée.
            </p>
            <p>
              Pour corser le tout,
              <strong>une maison à étage trouvera sa place sur un terrain étroit</strong> ou une
              petite superficie, alors le
              <strong>plain pied impliquera une terrain plus grand.</strong>
            </p>
            <img class="line-right" src="@/assets/img/pages/smartpage/line-3.svg" alt="Ligne de séparation entre les critères" />
          </div>
          <div>
            <img src="@/assets/img/pages/price-square-meter-5.jpg" alt="Maison Alysia avec garage" />
          </div>
        </div>
        <div class="smartpage-card-criterion criterion-constructive-mode">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>4</span>
              </div>
              <h2>Les choix du mode constructif</h2>
            </div>
            <p>
              Parpaing béton, béton cellulaire, brique alvéolaire ou brique Monomur, ossature bois ou
              métallique, <strong>ces matériaux ont tous des avantages et des inconvénients</strong>.
              Vous devrez évaluer toutes ces caractéristiques de solidité, d’isolation, d’emprunte
              carbone ou de recyclabilité, voire d’esthétique ou encore d’entretien.
            </p>
            <p>
              Tous les choix techniques influent évidement sur le prix mais faites confiance à votre
              constructeur, il saura vous conseiller et justifier de ses choix le plus souvent liés à
              la localisation, au climat et au style de la maison. Et bien souvent, le mode
              constructif constitue chez les constructeurs leur positionnement de gamme.
            </p>
            <img class="line-left" src="@/assets/img/pages/smartpage/line-4.svg" alt="Ligne de séparation entre les critères" />
          </div>
          <div>
            <img src="@/assets/img/pages/price-square-meter-6.jpg" alt="Maison Alysia avec garage" />
          </div>
        </div>
        <div class="smartpage-card-criterion criterion-right">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>5</span>
              </div>
              <h2>Le niveau des prestations, des finitions et des équipements</h2>
            </div>
            <div class="smartpage-card-criterion-img-wide">
              <img
                src="@/assets/img/pages/price-square-meter-4.jpg"
                alt="Maison Alysia avec garage"
              />
            </div>
            <p>
              Alors là, on rentre dans le grand arbitrage entre les envies et les contraintes. En
              effet, on touche la corde sensible car il est très légitime de vouloir de qu’il y a de
              mieux.
            </p>
            <ul>
              <li>
                <p>les ouvertures (PCV ou Aluminium)</p>
              </li>
              <li>
                <p>
                  le <strong>système de chauffage</strong> (plancher chauffant, chaudière gaz ou pompe
                  à chaleur)
                </p>
              </li>
              <li>
                <p>les <strong>revêtements de sols</strong> (carrelage ou parquet)</p>
              </li>
              <li>
                <p>les <strong>volets roulants</strong> (manuels ou électriques)</p>
              </li>
              <li>
                <p>la <strong>cuisine intégrée</strong> (ou pas)</p>
              </li>
              <li>
                <p>la <strong>salle de bain aménagée</strong> (ou pas)</p>
              </li>
              <li>
                <p>la <strong>domotique</strong> (ou pas)…</p>
              </li>
            </ul>
            <p>
              Sans compter <strong>les options et les aménagements extérieurs</strong> : terrasse,
              pergola, carport, portail, clôtures… Il y en a pour tous les goûts et donc pour des
              budgets… à la hausse.
            </p>
            <p>
              Upgrader son niveau de prestations peut vite faire grimper le prix de la maison de
              <strong>10 ou 20%, voire plus.</strong>
            </p>
            <p>
              En exigeant un devis de construction avec un descriptif précis et complet obtenu auprès
              de plusieurs constructeurs, il est possible de réaliser de sérieuses économies ou de
              faire grimper le niveau des prestations à budget fermé, notamment avec Maisons Alysia
              qui offre le meilleur rapport équipements/prix du marché. Donc un seul impératif pour
              votre projet de construction : COM-PA-RER !
            </p>
            <img class="line-right" src="@/assets/img/pages/smartpage/line-5.svg" alt="Ligne de séparation entre les critères" />
          </div>
        </div>
        <div class="smartpage-card-criterion criterion-left">
          <div>
            <div class="smartpage-card-criterion-title">
              <div class="smartpage-card-criterion-rank">
                <span>6</span>
              </div>
              <h2>La situation géographique</h2>
            </div>
            <p>
              Il varie principalement en fonction des matériaux utilisés d’une région à l’autre et ce
              pour des raisons géo-climatiques : c’est ce qu’on nomme l’architecture vernaculaire,
              très souvent associée à la notion de style.
              <strong>Plus le climat est froid, plus l’observation des normes thermiques (RE2020) impose
              un gros œuvre et une isolation performante, plus coûteuse pour la maison.</strong>
            </p>
            <p>
              C’est notamment remarquable en ce qui concerne la toiture et ses caractéristiques :
              tuile au sud, ardoise au nord (la frontière naturelle de la Loire a la vie dure), la
              pente, les débords, etc… De la même manière, on observe aussi des spécificités liées
              <strong>au prix du marché local de la main d’œuvre.</strong>
            </p>
            <p>
              Enfin, l’application des <strong>normes parasismiques françaises</strong> selon un
              zonage géographique précis entraine ou non l’adoption de choix techniques grévant de 1 à
              2% le prix de la construction. Toutes ces caractéristiques de marché ont donc des
              incidences tarifaires pouvant jouer dans un sens comme dans l’autre, et selon les
              régions, ce qui donne des résultats locaux très spécifiques.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-summary">
          <div class="smartpage-summary-body">
            <div class="smartpage-summary-content">
              <h2>En résumé</h2>
              <p>
                Connaître <strong>le prix moyen d’une maison est une information nécessaire mais pas suffisante</strong>.
                Vous l’avez compris, le coût d’un projet de construction <strong>s’articule autour de nombreux critères</strong>.
                Au final, vous comprenez que tout fonctionne comme des vases communicants :
              </p>
            </div>
            <div class="smartpage-summary-list">
              <ul>
                <li>
                  <p>Un meilleur terrain ou plus de prestations sur la maison</p>
                </li>
                <li>
                  <p>Maison à étage/petit terrain ou plain-pied/plus grand terrain</p>
                </li>
                <li>
                  <p>Mode constructif / Equipements</p>
                </li>
                <li>
                  <p>Chambre supplémentaire ou Garage</p>
                </li>
                <li>
                  <p>etc...</p>
                </li>
              </ul>
            </div>
            <div class="smartpage-summary-advice">
              <p>Beaucoup d’arbitrages… donc notre conseil :</p>
            </div>
            <div class="smartpage-summary-list">
              <ul>
                <li>
                  <p><strong>Définissez bien votre budget global</strong> (si vous ne l’avez pas encore fait, Maisons Alysia vous propose une <router-link :to="{ name: 'home' }">calculette budgetaire</router-link>)</p>
                </li>
                <li>
                  <p>
                    <strong>Listez vos souhaits et vos contraintes</strong>
                  </p>
                </li>
                <li>
                  <p><strong>Définissez vos critères essentiels et secondaires</strong> et vous pourrez alors moduler le coût de votre projet.</p>
                </li>
                <li>
                  <p>Faites des devis et comparer</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content">
        <div class="smartpage-champion">
          <h2>Maisons Alysia, le champion du prix au m2 ?</h2>
          <div class="smartpage-champion-img">
            <img src="@/assets/img/pages/price-square-meter-7.jpg" alt="Maisons Alysia, le champion du prix au m2 ?" />
          </div>
          <div class="smartpage-champion-body">
            <p>
              Maisons Alysia propose des modèles de maisons parmi les moins chères sur le marché, y compris dans la même catégorie.
            </p>
            <p>
              <strong>Et si vous jugiez par vous-même ?</strong>
            </p>
          </div>
        </div>
        <div class="smartpage-card-estimate">
          <h2>Comment obtenir un devis rapidement ?</h2>
          <p>Vous pensez qu’obtenir un prix de maison est fastidieux ?</p>
          <p>Vous avez raison : trouver 2 ou 3 constructeurs convenables, les contacter, prendre rendez-vous avec des commerciaux et attendre encore quelques jours pour enfin découvrir un prix. Le concept Maisons Alysia et son configurateur vous permettent de gagner du temps et vous propose une offre directement en ligne.</p>
          <ul>
            <li>
              <p>Configurez votre projet en ligne, gratuitement et sans engagement </p>
            </li>
            <li>
              <p>Définissez votre terrain, vos options, vos surfaces</p>
            </li>
            <li>
              <p>Obtenez directement votre devis et votre descriptif.</p>
            </li>
          </ul>
          <router-link to="/">Commencer mon projet</router-link>
        </div>
        <div class="smartpage-asterisk">
          <p>*Source : ministère de la transition écologique et solidaire (SOes, EPTB 2019 publiée le 24 novembre 2020). Derniers chiffres disponibles à la date de la dernière mise à jour (4 février 2021). Ces prix concernent la construction de maison individuelle en diffus.</p>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
export default {
  name: 'price-square-meter',
};
</script>

<style lang="sass">
.price-square-meter
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    @include smartpage-hero
  .article
    margin-bottom: 5rem
    section
      margin-top: 8rem
    .smartpage-card
      display: flex
      flex-direction: row
      .card-content
        box-shadow: $drop-med
        background-color: $off-white
        border-radius: $global-border-radius
        padding: 4rem 6rem
        h2
          margin-bottom: 2rem
        p:not(:last-child)
          margin-bottom: 1rem
      .square-meter-price
        display: flex
        flex-direction: column
        justify-content: center
        row-gap: 1.5rem
        > div
          display: flex
          justify-content: center
          flex-direction: column
          padding: 3rem 1.5rem
          text-align: center
          border-radius: 0 $global-border-radius $global-border-radius 0
        .square-meter-price-average
          background-color: $line
          p
            color: $black
            @include subtitle
        .square-meter-price-alysia
          background-color: $primary
          p
            @include subtitle
            color: $white
            &:not(:last-child)
              margin-bottom: 0.75rem
    .smartpage-divider
      background-color: $primary
      padding: 6rem 0 12rem 0
      &-content
        display: flex
        flex-direction: column
        width: 90%
        max-width: 900px
        margin: 0 auto
      &-body
        p, h2
          color: $white
          text-align: center
        h2
          margin-bottom: 2.25rem
        p:not(:last-child)
          margin-bottom: 1rem
      &-img
        display: flex
        justify-content: center
        margin-bottom: 2.25rem
        img
          width: 120px
      &-rank
        display: flex
        justify-content: center
        margin-top: -8rem
        > div
          box-shadow: $drop-med
          background-color: $off-white
          border-radius: $global-border-radius
          padding: 2rem 2.5rem
          width: 700px
        ul
          padding: 0
        span
          font-weight: 700
          color: $primary
        li:not(:last-child)
          margin-bottom: 0.5rem
        p
          @include body-bold
    .smartpage-card-criterion
      position: relative
      &:not(:last-child)
        margin-bottom: 6rem
      &-title
        display: grid
        grid-template-columns: 0.1fr 1fr
        grid-template-rows: 1fr
        margin-bottom: 2.5rem
        column-gap: 1rem
      &-rank
        position: relative
        display: flex
        border-radius: 50%
        background-color: $primary
        width: 50px
        height: 50px
        span
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          color: $white
          font-weight: 700
          font-size: 2rem
      > div:first-child
        box-shadow: $drop-med
        background-color: $off-white
        border-radius: $global-border-radius
        padding: 4rem 6rem
        p:not(:last-child)
          margin-bottom: 1rem
        ul
          margin-bottom: 1rem
          li
            list-style-image: url("../../assets/img/arrow-blue.svg")
            &:not(:last-child)
              margin-bottom: 1rem
      &-img
        display: flex
        flex-direction: row
        column-gap: 1rem
        margin-bottom: 1rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-img-wide
        width: 100%
        margin-bottom: 1rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
    .criterion-left
      margin-left: 8rem
    .criterion-right
      margin-right: 8rem
    .criterion-home-type
      display: flex
      flex-direction: row
      > div:first-child
        padding: 4rem 10rem 4rem 6rem
      > div:last-child
        width: 600px
        display: flex
        align-items: center
        margin-left: -8rem
        img
          object-fit: cover
          width: 100%
          height: 75%;
          border-radius: $global-border-radius
    .criterion-constructive-mode
      display: flex
      flex-direction: column
      > div:first-child
        margin-left: 16rem
        padding: 4rem 6rem 4rem 10rem
      > div:last-child
        width: 240px
        display: flex
        margin-top: -16rem
        margin-left: 8rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
    .line-right
      position: absolute
      right: -6.5rem
      bottom: -6.5rem
    .line-left
      position: absolute
      right: 38.1rem
      bottom: -7.5rem
    .line-left
      position: absolute
      right: 38.1rem
      bottom: -7.5rem
    .smartpage-summary
      background-color: $primary
      padding: 4rem 0
      &-body
        width: 90%
        max-width: 900px
        margin: 0 auto
      &-content
        h2, p
          text-align: center
          color: $white
          margin-bottom: 2.5rem
      &-advice
        margin: 2.5rem 0
        p
          text-align: center
          color: $white
      &-list
        box-shadow: $drop-med
        background-color: $off-white
        border-radius: $global-border-radius
        padding: 1.5rem 2.5rem
        &:first-child
          margin-bottom: 2.5rem
        ul
          padding-left: 1.5rem
          li
            list-style-image: url("../../assets/img/arrow-blue.svg")
            &:not(:last-child)
              margin-bottom: 1rem
    .smartpage-champion
      margin-bottom: 6rem
      h2
        color: $primary
        text-align: center
        margin-bottom: 2rem
      &-img
        width: 100%
        margin-bottom: 2rem
        img
          object-fit: cover
          width: 100%
          border-radius: $global-border-radius
      &-body
        width: 90%
        max-width: 1000px
        margin: 0 auto
    .smartpage-card-estimate
      box-shadow: $drop-med
      background-color: $off-white
      border-radius: $global-border-radius
      padding: 4rem 6rem
      margin-bottom: 2.5rem
      h2
        color: $primary
        margin-bottom: 2.5rem
      p:not(:last-child)
        margin-bottom: 1rem
      ul
        margin: 2rem 0
        padding-left: 1.5rem
        li
          list-style-image: url("../../assets/img/arrow-blue.svg")
          &:not(:last-child)
            margin-bottom: 1rem
      a
        display: inline-flex
        flex-direction: row
        justify-content: center
        align-items: center
        padding: 16px 20px 17px
        outline: none
        color: $off-white
        border-radius: 4px
        transition: all 0.3s ease-in-out
        user-select: none
        cursor: pointer
        background-color: $primary
        text-decoration: none
        &:hover
          background-color: $primary-light
        &:active
          background-color: $primary-dark
    .smartpage-asterisk
      p
        font-weight: 700
        font-size: $font-xxs
  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto
  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        &:first-child
          margin-top: 8rem
        margin-top: 6rem
      .smartpage-card
        flex-direction: column-reverse
        box-shadow: $drop-med
        background-color: $off-white
        border-radius: $global-border-radius
        padding: 1.5rem 1rem
        .card-content
          border-radius: none
          background-color: transparent
          box-shadow: none
          padding: 0
          margin-top: 1.5rem
          h2
            margin-bottom: 1.5rem
        .square-meter-price
          row-gap: 0.5rem
          > div
            border-radius: $global-border-radius
      .smartpage-divider
        padding: 6rem 0 2rem 0
        &-rank
          width: 90%
          margin: 0 auto
          position: relative
          z-index: 1
          > div
            z-index: 1
          &::after
            content: ""
            background: $primary
            width: 100vw
            height: calc(100% + 4rem)
            position: absolute
            bottom: -2rem
            z-index: 0
      .criterion-left
        margin-left: 0
      .criterion-right
        margin-right: 0
      .line-left
        display: none
      .line-right
        display: none
      .smartpage-card-criterion
        &:not(:last-child)
          margin-bottom: 2rem
        > div:first-child
          padding: 1.5rem 1rem
        &-title
          span
            font-size: $font-sm
        &-img
          flex-direction: column
          img:not(:last-child)
            margin-bottom: 1rem
      .criterion-constructive-mode
        > div:first-child
          margin-left: 0
        > div:last-child
          display: none
      .criterion-home-type
        > div:last-child
          display: none
      .smartpage-champion
        margin-bottom: 2rem
      .smartpage-card-estimate
        padding: 1.5rem 1rem
</style>
